const Home = () => {
  document.title = "Home";

  return (
    <div className="center-container">
      <div className="text-container">
        <p>
          Yuudachi.co.uk is a website I started as a way to teach myself React and as a revision tool for my Japanese lessons. This is purely a hobby 
          site so there may be some things missing or some mistakes. This also means the categories are purely arbitrary, but should hopefully make enough sense.
        </p>
        <p>
          The site is currently under construction and will continually grow in features and vocabulary. If anything needs correcting, or you have any suggestions/feedback please send an email to yuudachi.site@gmail.com
        </p>
      </div>
      <img className="image" src="/img/yuudachi.webp"/>
    </div>
  );
}

export default Home;
