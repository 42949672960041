import Body from "./components/Body"
import Home from "./pages/Home"
import Nouns from "./pages/Nouns"
import Syllabary from "./pages/Syllabary"
import KanaQuiz from "./pages/KanaQuiz"
import InaQuiz from "./pages/InaQuiz"
import VerbGroupsQuiz from "./pages/VerbGroupsQuiz"
import { Route, Routes, useLocation } from 'react-router-dom'
import { useEffect } from 'react'


const App = () => {

  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  return (
    <Body>
      <Routes>
        <Route path="/" element = {<Home />} />

        <Route path="/syllabary" element = {<Syllabary />} />

        <Route path="/nouns" element = {<Nouns />} />

        <Route path="/quizzes/kana-quiz" element = {<KanaQuiz />} />
        <Route path="/quizzes/ina-quiz" element = {<InaQuiz />} />
        <Route path="/quizzes/verb-groups-quiz" element = {<VerbGroupsQuiz />} />
      </Routes>
  </Body>
  );
}

export default App;
