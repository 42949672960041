import Adjectives from '../Adjectives';
import { getAdjectiveById } from '../Adjectives';
import Header from './../components/Header';
import Button from './../components/input/Button';
import { useState } from 'react';
import { Tooltip } from '@mui/material';

//As const
const InaQuiz = () => {



  const [adjective, setQuestion] = useState(Adjectives[Math.floor(Math.random() * Adjectives.length)]);
  
  //Check Answer
  const checkAnswer = (adjectiveId, i) => {
    let adjective = getAdjectiveById(adjectiveId)
    if (adjective.i === i) {
      alert("CORRECT");
      setQuestion(Adjectives[Math.floor(Math.random() * Adjectives.length)]);
    } else {
      alert("INCORRECT");
    }
  }

  return (
    <div className="question-container">
      <Header title="い or な"/>
      <p className="question toolTip">{adjective.kanji}</p>
      <div className="answer-container">
        <Button classes="answer-button" text="い" onClick={() => checkAnswer(adjective.id, true)}/>
        <Button classes="answer-button" text="な" onClick={() => checkAnswer(adjective.id, false)}/>
      </div>
    </div>
  );
}

//As Class
// import React from 'react'

// class App extends React.Component {
//   render() {
//     const name = 'adjective question'
  
//     return (
//       <div className="InaQuiz">
//         <div className="container">
//           <Header/>
//           <p className="question toolTip">{name}</p>
//           <div className="answer-container">
//             <button>い</button>
//             <button>な</button>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }


//As Function
// function InaQuiz() {
//   const name = 'adjective question'

//   return (
//     <div className="App">
//       <div className="container">
//         <Header/>
//         <p className="question toolTip">{name}</p>
//         <div className="answer-container">
//           <button>い</button>
//           <button>な</button>
//         </div>
//       </div>
//     </div>
//   );
// }




export default InaQuiz;
