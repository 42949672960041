import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import './../css/navbar.css'
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

const pages = [
  {title: 'Syllabary', href: '/syllabary'},
  {title: 'Nouns', href: '/nouns'},
  {title: 'Adjectives', href: '/adjectives'},
  {title: 'Verbs', href: '/verbs'},
  {title: 'Quizzes', href: '/quizzes', subpages: [
    {title: 'Kana', href: '/quizzes/kana-quiz'},
    {title: 'い or な', href: '/quizzes/ina-quiz'},
    {title: 'Verb Groups', href: '/quizzes/verb-groups-quiz'}
  ]}
];

const NavBar = () => {

  const [sideNavOpen, setSideNavOpen] = useState(false);

  const switchSideNavOpen = () => {
    sideNavOpen ? document.body.style.overflow = null : document.body.style.overflow = "hidden";
    setSideNavOpen(!sideNavOpen);
  }

  const closeSideNav = () => {
    document.body.style.overflow = null;
    setSideNavOpen(false);
  }

  function NavBarItem( {page} ) {
    return (
      <li>
        <NavLink className="navbar-item" to={page.href} onClick={closeSideNav}><span data-text={page.title}>{page.title}</span></NavLink>
      </li>
    )
  }

  function NavBarDropdownMenu({page}) {

    const [open, setOpen] = useState(false);


    const openDropDownMenu = () => {
      setOpen(true);
    };
  
    const closeDropDownMenu = () => {
      setOpen(false);
    };

    return (
      <li>
        <NavLink end className="navbar-item" to={page.href} onMouseOver={openDropDownMenu} onMouseOut={closeDropDownMenu}><span data-text={page.title}>{page.title}</span><i className={"fas fa-angle-down" + (open ? " open" : "")}></i></NavLink>
        <SlideDown className="dropdown" onMouseOver={openDropDownMenu} onMouseOut={closeDropDownMenu} closed={!open}>
          <ul>
            {page.subpages.map((subpage) => (
              <li>
                <NavLink end className="dropdown-item" to={subpage.href} onClick={closeDropDownMenu}><span data-text={subpage.title}>{subpage.title}</span></NavLink>
              </li>
            ))}
          </ul>
        </SlideDown>
      </li>
    )
  }


  return (
    <>
      <div className="navbar-ghost"></div>
      <nav className="navbar">
        
        <div className="nav-logo-container">
          <img className="logo" src={process.env.PUBLIC_URL + '/favicon32.png'} />
          <Link key="logo" to="/" className="site-title"><span>ぽい</span></Link>
        </div>

        <ul>
          {pages.map((page) => (
            <>
              {page.subpages ? <NavBarDropdownMenu page={page}/> : <NavBarItem page={page} />} 
            </>
          ))}
        </ul>
        <button className={"hamburger" + (sideNavOpen ? " selected" : "")} onClick={switchSideNavOpen}>
          <i className={sideNavOpen ? "fa-solid fa-xmark" : "fa-solid fa-bars"}></i>
        </button>
      </nav>
      
      <div className={"sidenav" + (sideNavOpen ? "" : " hide")}>
        <div className="menu">
            {pages.map((page) => (
              <div className="item">
                {page.subpages ? <SubMenu page={page} onClick={closeSideNav}/> : <NavLink end to={page.href} onClick={closeSideNav}><span data-text={page.title}>{page.title}</span></NavLink>}
              </div>
            ))}
        </div>
      </div>
    </>
  )
}

const SubMenu = ({page, onClick}) => {
  const [open, setOpen] = useState(false);

  const handleMenuClick = () => {
    setOpen(!open);
  }

  return (
    <>
      <a className="sub-btn" onClick={handleMenuClick}><span data-text={page.title}>{page.title}</span><i className={"fas fa-angle-down" + (open ? " open" : "")}></i></a>
      <SlideDown className="sub-menu" closed={!open}>
        {page.subpages.map((subpage) => (
          <NavLink end className="sub-item" to={subpage.href} onClick={onClick}><span data-text={subpage.title}>{subpage.title}</span></NavLink>
        ))}
      </SlideDown >
    </>
  )
}

export default NavBar