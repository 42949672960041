import NavBar from './NavBar';

const Body = (props) => {

  return (
    <div className="body">
        <NavBar />
        <div className="container">
          {props.children}
        </div>
    </div>
  );
}

export default Body;
