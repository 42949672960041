import { useState, useEffect } from 'react';
import { Hiragana, DakuonHiragana, DakuonKatakana, HandakuonHiragana, HandakuonKatakana, Katakana, YouonHiragana, YouonKatakana, YouonHandakuonHiragana, YouonDakuonHiragana, YouonDakuonKatakana, YouonHandakuonKatakana } from '../data/Kana';
import { getKanaById, getPossibleEnKana} from '../data/Kana';
import "./../css/quiz/kanaQuiz.css"

const validAnswers = getPossibleEnKana()

const options = ["Hiragana", "Hiragana Dakuon and Handakuon", "Hiragana Youon", "Katakana", "Katakana Dakuon and Handakuon", "Katakana Youon"]

const KanaQuiz = () => {

  const [answer, setAnswer] = useState("")

  const [kanas, setKanas] = useState(Hiragana)

  const [kana, setKana] = useState(kanas[Math.floor(Math.random() * kanas.length)]);

  const [isCorrect, setIsCorrect] = useState(null)

  const [optionsState, setOptionsState] = useState([true, false, false, false, false, false])

  const [history, setHistory] = useState([])

  useEffect(() => {
    updateKanas();
  }, [optionsState]); 

  useEffect(() => {
    setKana(kanas[Math.floor(Math.random() * kanas.length)]);
  }, [kanas])

  useEffect(() => {
    if (isCorrect == null) {
      document.getElementById("kana-answer-input").focus();
    }
  }, [isCorrect])

  function pushHistory(kana, en, answer, correct) {
    if (history.length > 15) {
      history.pop()
    }
    setHistory([{"kana": kana, "en": en, "answer": answer, "correct": correct}].concat(history))
  }

  function optionChange(index) {
    let oldState = optionsState[index]

    if (canChangeCheckBox(oldState)) {
      let newOptionState = [...optionsState]
      newOptionState[index] = !oldState
      setOptionsState(newOptionState)
    }
  }

  function canChangeCheckBox(checkboxState) {
    return !(onlyOneCheckboxChecked() && checkboxState)
  }

  function onlyOneCheckboxChecked() {
    return (1 === optionsState.filter(value => value === true).length)
  }

  function updateKanas() {
    let newKana = []

    if (optionsState[0]) {
      newKana = newKana.concat(Hiragana)
    }

    if (optionsState[1]) {
      newKana = newKana.concat(DakuonHiragana).concat(HandakuonHiragana)
    }

    if (optionsState[2]) {
      newKana = newKana.concat(YouonHiragana).concat(YouonDakuonHiragana).concat(YouonHandakuonHiragana)
    }

    if (optionsState[3]) {
      newKana = newKana.concat(Katakana)
    }

    if (optionsState[4]) {
      newKana = newKana.concat(DakuonKatakana).concat(HandakuonKatakana)
    }

    if (optionsState[5]) {
      newKana = newKana.concat(YouonKatakana).concat(YouonDakuonKatakana).concat(YouonHandakuonKatakana)
    }

    setKanas(newKana)
  }

  const onAnswerChange = (answer) => {
    setAnswer(answer)

    if (validAnswers.includes(answer)) {
      if (answer === "n" && kana.en.includes("n")) {
        handleAnswer(true, kana.kana, kana.en, answer);
      } else if (answer != "n") {
        if (isCorrectAnswer(answer)) {
          handleAnswer(true, kana.kana, kana.en, answer);
        } else {
          handleAnswer(false, kana.kana, kana.en, answer);
        }
      }
    }
  }

  function handleAnswer(correct, kana, en, answer) {
    pushHistory(kana, en, answer, correct);
    setIsCorrect(correct);
    setTimeout(function() {
      setIsCorrect(null);
      setAnswer("");
      setKana(kanas[Math.floor(Math.random() * kanas.length)]);
    }, 750);
  }

  //Check Answer
  const isCorrectAnswer = (answer) => {
    return getKanaById(kana.id).en.includes(answer)
  }

  return (
    <div className="kana-quiz-container">
      <div className="kana-question-container">
        <p className="question">{kana.kana}</p>
        <input id="kana-answer-input" className={"kana-answer-input" + (isCorrect != null ? (isCorrect ? " correct" : " incorrect") : "")} type="text" maxLength="3" onChange={(e) => { onAnswerChange(e.target.value)}} value={answer} disabled={isCorrect != null} autoComplete="off"/>
        <div className="kana-quiz-options-container">
          {options.map((option, index) => {
            return (
              <label>
                <input
                  id={"option-checkbox-" + {index}}
                  type="checkbox"
                  checked={optionsState[index]}
                  onChange={() => optionChange(index)}
                />
                {option}
              </label>
            )
          })}
        </div>
      </div>
      <div className="kana-question-history horizontal">
          {history.map((history) => (
            <div className={"kana-answer-history-item" + (history.correct ? " correct" : " incorrect")}>
              <p className="kana-answer-history-item-text">{history.kana}</p>
              <p className="kana-answer-history-item-text">{history.en}</p>
              {history.correct ? "" : <p className="kana-answer-history-item-text">{history.answer}</p>}
            </div>
          ))}
        </div>
    </div>
  );
}

export default KanaQuiz;