import {Nouns as Words, getNounTitles} from "./../Nouns"
import SectionNav from "./../components/SectionNav"


function NounSection({id, title, nouns}) {
  return (
    <section className="content-section" id={id}>
      <h2>{title}</h2>
      <table className="word-table">
        <col className="column-kanji"/>
        <col className="column-hiragana"/>
        <col className="column-english"/>
        <tbody>
          {nouns.map((noun) => (
            <tr>
              <td>{noun.kanji}</td>
              <td>{noun.hiragana}</td>
              <td>{noun.english}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  )
}

function Nouns() {
  document.title = "Nouns";

  return (
    <div className="content">
      <SectionNav sections={Words}/>
      <div className="section-content">
        <h1>Nouns</h1>
        <div>
          {Words.map((type) => {
            return <NounSection id={type.id} title={type.title} nouns={type.data}/>
          })}
        </div>

      </div>
    </div>
  )
}

export default Nouns