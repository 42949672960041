import SectionContent from "../components/SectionContent";
import SectionNav from "../components/SectionNav";

const sections = [
  { "id": "syllabary", "title": "Syllabary"},
  { "id": "hiragana", "title": "Hiragana"},
  { "id": "katakana", "title": "Katakana"},
  {"id": "dakuon_and_handakuon", "title": "Dakuon and Handakuon"},
  {"id": "youon", "title": "Youon"}
]

function KanaCell({kana, syllable}) {
  return (
    <>
      <div>{kana}</div>
      <div className="syllable">{syllable}</div>
    </>

  )
} 

function Syllabary() {
  document.title = "Syllabary";

  return (
    
    <div className="content">
      <SectionNav sections={sections}/>
      <div className="section-content">
      <h1>Syllabary</h1>
        <SectionContent id="syllabary">
        <p>
          The Japanese language makes use of two different sets of characters to represent the different syllables, Hiragana and Katakana.
          Each character set has 46 (commonly used) characters and are made up of 5 vowels, 40 consanant-vowel pairs and 1 consanant with
          each character representing a unique syllable and some can be given 2 different accents, known as dakuten and handakuten,
          to slightly change the sound of the syllable. 
        </p>
          <img className="image rounded-corners" style={{width: "700px"}} src="/img/hiragana-katakana.png"/>
        </SectionContent>

        <SectionContent id="hiragana">
          <h2>Hiragana</h2>
          <p>Hiragana has a more cursive style to its characters than Katakana and is used to write kana prefixes and suffixes with kanji, as particles
            and other miscellaneous words that have no kanji.</p>
          <table className="kana-table">
            <tr><td></td><th>-a</th><th>-i</th><th>-u</th><th>-e</th><th>-o</th></tr>
            <tr>
              <th>-</th>
              <td><KanaCell kana="あ" syllable="a"/></td>
              <td><KanaCell kana="い" syllable="i"/></td>
              <td><KanaCell kana="う" syllable="u"/></td>
              <td><KanaCell kana="え" syllable="e"/></td>
              <td><KanaCell kana="お" syllable="o"/></td>
            </tr>

            <tr>
              <th>k-</th>
              <td><KanaCell kana="か" syllable="ka"/></td>
              <td><KanaCell kana="き" syllable="ki"/></td>
              <td><KanaCell kana="く" syllable="ku"/></td>
              <td><KanaCell kana="け" syllable="ke"/></td>
              <td><KanaCell kana="こ" syllable="ko"/></td>
            </tr>

            <tr>
              <th>s-</th>
              <td><KanaCell kana="さ" syllable="sa"/></td>
              <td><KanaCell kana="し" syllable="shi"/></td>
              <td><KanaCell kana="す" syllable="su"/></td>
              <td><KanaCell kana="せ" syllable="se"/></td>
              <td><KanaCell kana="そ" syllable="so"/></td>
            </tr>

            <tr>
              <th>t-</th>
              <td><KanaCell kana="た" syllable="ta"/></td>
              <td><KanaCell kana="ち" syllable="chi"/></td>
              <td><KanaCell kana="つ" syllable="tsu"/></td>
              <td><KanaCell kana="て" syllable="te"/></td>
              <td><KanaCell kana="と" syllable="to"/></td>
            </tr>

            <tr>
              <th>n-</th>
              <td><KanaCell kana="な" syllable="na"/></td>
              <td><KanaCell kana="に" syllable="ni"/></td>
              <td><KanaCell kana="ぬ" syllable="nu"/></td>
              <td><KanaCell kana="ね" syllable="ne"/></td>
              <td><KanaCell kana="の" syllable="no"/></td>
            </tr>

            <tr>
              <th>h-</th>
              <td><KanaCell kana="は" syllable="ha"/></td>
              <td><KanaCell kana="ひ" syllable="hi"/></td>
              <td><KanaCell kana="ふ" syllable="fu"/></td>
              <td><KanaCell kana="へ" syllable="he"/></td>
              <td><KanaCell kana="ほ" syllable="ho"/></td>
            </tr>

            <tr>
              <th>m-</th>
              <td><KanaCell kana="ま" syllable="ma"/></td>
              <td><KanaCell kana="み" syllable="mi"/></td>
              <td><KanaCell kana="む" syllable="mu"/></td>
              <td><KanaCell kana="め" syllable="me"/></td>
              <td><KanaCell kana="も" syllable="mo"/></td>
            </tr>

            <tr>
              <th>y-</th>
              <td><KanaCell kana="や" syllable="ya"/></td>
              <td></td>
              <td><KanaCell kana="ゆ" syllable="yu"/></td>
              <td></td>
              <td><KanaCell kana="よ" syllable="yo"/></td>
            </tr>

            <tr>
              <th>r-</th>
              <td><KanaCell kana="ら" syllable="ra"/></td>
              <td><KanaCell kana="り" syllable="ri"/></td>
              <td><KanaCell kana="る" syllable="ru"/></td>
              <td><KanaCell kana="れ" syllable="re"/></td>
              <td><KanaCell kana="ろ" syllable="ro"/></td>
            </tr>

            <tr>
              <th>w-</th>
              <td><KanaCell kana="わ" syllable="wa"/></td>
              <td></td>
              <td></td>
              <td></td>
              <td><KanaCell kana="を" syllable="wo"/></td>
            </tr>

            <tr>
              <th>n</th>
              <td colspan="5"><KanaCell kana="ん" syllable="n"/></td>
            </tr>           

          </table>
        </SectionContent>

        <SectionContent id="katakana">
          <h2>Katakana</h2>
          <p>Katakana makes use of more straight edges and sharp corners and is predominantly used for writing non-japanese words,
            like names and loan words, but can also be used to show emphasis or for onomatopoeia.</p>
          <table className="kana-table">
            <tr><td></td><th>-a</th><th>-i</th><th>-u</th><th>-e</th><th>-o</th></tr>
            <tr>
              <th>-</th>
              <td><KanaCell kana="ア" syllable="a"/></td>
              <td><KanaCell kana="イ" syllable="i"/></td>
              <td><KanaCell kana="ウ" syllable="u"/></td>
              <td><KanaCell kana="エ" syllable="e"/></td>
              <td><KanaCell kana="オ" syllable="o"/></td>
            </tr>

            <tr>
              <th>k-</th>
              <td><KanaCell kana="カ" syllable="ka"/></td>
              <td><KanaCell kana="キ" syllable="ki"/></td>
              <td><KanaCell kana="ク" syllable="ku"/></td>
              <td><KanaCell kana="ケ" syllable="ke"/></td>
              <td><KanaCell kana="コ" syllable="ko"/></td>
            </tr>

            <tr>
              <th>s-</th>
              <td><KanaCell kana="サ" syllable="sa"/></td>
              <td><KanaCell kana="シ" syllable="shi"/></td>
              <td><KanaCell kana="ス" syllable="su"/></td>
              <td><KanaCell kana="セ" syllable="se"/></td>
              <td><KanaCell kana="ソ" syllable="so"/></td>
            </tr>

            <tr>
              <th>t-</th>
              <td><KanaCell kana="タ" syllable="ta"/></td>
              <td><KanaCell kana="チ" syllable="chi"/></td>
              <td><KanaCell kana="ツ" syllable="tsu"/></td>
              <td><KanaCell kana="テ" syllable="te"/></td>
              <td><KanaCell kana="ト" syllable="to"/></td>
            </tr>

            <tr>
              <th>h-</th>
              <td><KanaCell kana="ハ" syllable="ha"/></td>
              <td><KanaCell kana="ヒ" syllable="hi"/></td>
              <td><KanaCell kana="フ" syllable="fu"/></td>
              <td><KanaCell kana="ヘ" syllable="he"/></td>
              <td><KanaCell kana="ホ" syllable="ho"/></td>
            </tr>

            <tr>
              <th>n-</th>
              <td><KanaCell kana="ナ" syllable="na"/></td>
              <td><KanaCell kana="ニ" syllable="ni"/></td>
              <td><KanaCell kana="ヌ" syllable="nu"/></td>
              <td><KanaCell kana="ネ" syllable="ne"/></td>
              <td><KanaCell kana="ノ" syllable="no"/></td>
            </tr>

            <tr>
              <th>m-</th>
              <td><KanaCell kana="マ" syllable="ma"/></td>
              <td><KanaCell kana="ミ" syllable="mi"/></td>
              <td><KanaCell kana="ム" syllable="mu"/></td>
              <td><KanaCell kana="メ" syllable="me"/></td>
              <td><KanaCell kana="モ" syllable="mo"/></td>
            </tr>

            <tr>
              <th>y-</th>
              <td><KanaCell kana="ヤ" syllable="ya"/></td>
              <td></td>
              <td><KanaCell kana="ユ" syllable="yu"/></td>
              <td></td>
              <td><KanaCell kana="ヨ" syllable="yo"/></td>
            </tr>

            <tr>
              <th>r-</th>
              <td><KanaCell kana="ラ" syllable="ra"/></td>
              <td><KanaCell kana="リ" syllable="ri"/></td>
              <td><KanaCell kana="ル" syllable="ru"/></td>
              <td><KanaCell kana="レ" syllable="re"/></td>
              <td><KanaCell kana="ロ" syllable="ro"/></td>
            </tr>

            <tr>
              <th>w-</th>
              <td><KanaCell kana="ワ" syllable="wa"/></td>
              <td></td>
              <td></td>
              <td></td>
              <td><KanaCell kana="ヲ" syllable="wo"/></td>
            </tr>

            <tr>
              <th>n</th>
              <td colspan="5"><KanaCell kana="ン" syllable="n"/></td>
            </tr>           

          </table>
        </SectionContent>

        <SectionContent id="dakuon_and_handakuon">
          <h2>Dakuon and Handakuon</h2>
          <p>
            Dakuone and handakuon are slight variations of some kana and are represented by adding a dakuaten and handakuten, respectively.
            The dakuten and handakuten are two accents that can be added to specific characters of both Hiragana and Katakana to alter the
            pronunciation slightly.
          </p>

          <p>
            The dakuten looks like two small quotation marks placed at the top right of the character and can be applied
            to k-, s-, t-, and h- characters.
          </p>
          <table className="kana-table">
            <tr><td></td><th>-a</th><th>-i</th><th>-u</th><th>-e</th><th>-o</th></tr>
            <tr>
              <th>k-</th>
              <td><KanaCell kana="が ガ" syllable="ga"/></td>
              <td><KanaCell kana="ぎ ギ" syllable="gi"/></td>
              <td><KanaCell kana="ぐ グ" syllable="gu"/></td>
              <td><KanaCell kana="げ ゲ" syllable="ge"/></td>
              <td><KanaCell kana="ご ゴ" syllable="go"/></td>
            </tr>

            <tr>
              <th>s-</th>
              <td><KanaCell kana="ざ ザ" syllable="za"/></td>
              <td><KanaCell kana="じ ジ" syllable="ji"/></td>
              <td><KanaCell kana="ず ズ" syllable="zu"/></td>
              <td><KanaCell kana="ぜ ゼ" syllable="ze"/></td>
              <td><KanaCell kana="ぞ ゾ" syllable="zo"/></td>
            </tr>

            <tr>
              <th>t-</th>
              <td><KanaCell kana="だ ダ" syllable="da"/></td>
              <td><KanaCell kana="ぢ ヂ" syllable="ji"/></td>
              <td><KanaCell kana="づ ヅ" syllable="zu"/></td>
              <td><KanaCell kana="で デ" syllable="de"/></td>
              <td><KanaCell kana="ど ド" syllable="do"/></td>
            </tr>

            <tr>
              <th>h-</th>
              <td><KanaCell kana="ば バ" syllable="ba"/></td>
              <td><KanaCell kana="び ビ" syllable="bi"/></td>
              <td><KanaCell kana="ぶ ブ" syllable="bu"/></td>
              <td><KanaCell kana="べ ベ" syllable="be"/></td>
              <td><KanaCell kana="ぼ ボ" syllable="bo"/></td>
            </tr>
          </table>

          <p>
            The handakuten looks like a small circle placed at the top right of the character and can be
            applied to h- characters to make a p- sound.
          </p>
          <table className="kana-table">
            <tr><td></td><th>-a</th><th>-i</th><th>-u</th><th>-e</th><th>-o</th></tr>
            <tr>
              <th>h-</th>
              <td><KanaCell kana="ぱ パ" syllable="pa"/></td>
              <td><KanaCell kana="ぴ ピ" syllable="pi"/></td>
              <td><KanaCell kana="ぷ プ" syllable="pu"/></td>
              <td><KanaCell kana="ぺ ペ" syllable="pe"/></td>
              <td><KanaCell kana="ぽ ポ" syllable="po"/></td>
            </tr>
          </table>

          <p>
            Notice that the dakuten versions of shi and chi, and su and tsu sound the same phonetically, respectively, and is the case for most of the spoken Japanese language.
            However, it is worth noting that there are some dialects where that isn't the case, and instead have four different sounds or even just one.
          </p>
        </SectionContent>

        <SectionContent id="youon">
          <h2>Youon</h2>
          <p>
            Youon are represented by an "-i" kana, followed by a smaller verison of one of the "y-" kana. This removes the "-i" sound almost entirely from the syllable and replaces
            it with the "y-" kana, so "きゃ" becomes "kya".
          </p>
          <table className="kana-table">
            <tr><td></td><th colspan="2">-ya</th><th colspan="2">-yu</th><th colspan="2">-yo</th></tr>
            <tr>
              <th>k-</th>
              <td colspan="2"><KanaCell kana="きゃ キャ" syllable="kya"/></td>
              <td colspan="2"><KanaCell kana="きゅ キュ" syllable="kyu"/></td>
              <td colspan="2"><KanaCell kana="きょ キョ" syllable="kyo"/></td>
            </tr>

            <tr>
              <th>sh-</th>
              <td colspan="2"><KanaCell kana="しゃ シャ" syllable="sha"/></td>
              <td colspan="2"><KanaCell kana="しゅ シュ" syllable="shu"/></td>
              <td colspan="2"><KanaCell kana="しょ ショ" syllable="sho"/></td>
            </tr>

            <tr>
              <th>n-</th>
              <td colspan="2"><KanaCell kana="にゃ ニャ" syllable="nya"/></td>
              <td colspan="2"><KanaCell kana="にゅ ニュ" syllable="nyu"/></td>
              <td colspan="2"><KanaCell kana="にょ ニョ" syllable="nyo"/></td>
            </tr>

            <tr>
              <th>h-</th>
              <td colspan="2"><KanaCell kana="ひゃ ヒャ" syllable="hya"/></td>
              <td colspan="2"><KanaCell kana="ひゅ ヒュ" syllable="hyu"/></td>
              <td colspan="2"><KanaCell kana="ひょ ヒョ" syllable="hyo"/></td>
            </tr>

            <tr>
              <th>m-</th>
              <td colspan="2"><KanaCell kana="みゃ ミャ" syllable="mya"/></td>
              <td colspan="2"><KanaCell kana="みゅ ミュ" syllable="myu"/></td>
              <td colspan="2"><KanaCell kana="みょ ミョ" syllable="myo"/></td>
            </tr>

            <tr>
              <th>r-</th>
              <td colspan="2"><KanaCell kana="りゃ リャ" syllable="rya"/></td>
              <td colspan="2"><KanaCell kana="りゅ リュ" syllable="ryu"/></td>
              <td colspan="2"><KanaCell kana="りょ リョ" syllable="ryo"/></td>
            </tr>
          </table>

          <p>
            Dakuten and handakuten can also be applied to the "-i" kana
          </p>

          <table className="kana-table">
            <tr><td></td><th colspan="2">-ya</th><th colspan="2">-yu</th><th colspan="2">-yo</th></tr>
            <tr>
              <th>g-</th>
              <td colspan="2"><KanaCell kana="ぎゃ ギャ" syllable="gya"/></td>
              <td colspan="2"><KanaCell kana="ぎゅ ギュ" syllable="gyu"/></td>
              <td colspan="2"><KanaCell kana="ぎょ ギョ" syllable="gyo"/></td>
            </tr>

            <tr>
              <th>j-</th>
              <td colspan="2"><KanaCell kana="じゃ ジャ" syllable="ja"/></td>
              <td colspan="2"><KanaCell kana="じゅ ジュ" syllable="ju"/></td>
              <td colspan="2"><KanaCell kana="じょ ジョ" syllable="jo"/></td>
            </tr>

            <tr>
              <th>j-</th>
              <td colspan="2"><KanaCell kana="ぢゃ ヂャ" syllable="ja"/></td>
              <td colspan="2"><KanaCell kana="ぢゅ ヂュ" syllable="ju"/></td>
              <td colspan="2"><KanaCell kana="ぢょ ヂョ" syllable="jo"/></td>
            </tr>

            <tr>
              <th>b-</th>
              <td colspan="2"><KanaCell kana="びゃ ビャ" syllable="bya"/></td>
              <td colspan="2"><KanaCell kana="びゅ ビュ" syllable="byu"/></td>
              <td colspan="2"><KanaCell kana="びょ ビョ" syllable="byo"/></td>
            </tr>

            <tr>
              <th>p-</th>
              <td colspan="2"><KanaCell kana="ぴゃ ピャ" syllable="pya"/></td>
              <td colspan="2"><KanaCell kana="ぴゅ ピュ" syllable="pyu"/></td>
              <td colspan="2"><KanaCell kana="ぴょ ピョ" syllable="pyo"/></td>
            </tr>
          </table>
        </SectionContent>

      </div>
    </div>
  )
}

export default Syllabary