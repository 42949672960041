export const Hiragana = [
  {"id": 0, "kana": "あ", "en": ["a"]},
  {"id": 1, "kana": "い", "en": ["i"]},
  {"id": 2, "kana": "う", "en": ["u"]},
  {"id": 3, "kana": "え", "en": ["e"]},
  {"id": 4, "kana": "お", "en": ["o"]},
  
  {"id": 5, "kana": "か", "en": ["ka"]},
  {"id": 6, "kana": "き", "en": ["ki"]},
  {"id": 7, "kana": "く", "en": ["ku"]},
  {"id": 8, "kana": "け", "en": ["ke"]},
  {"id": 9, "kana": "こ", "en": ["ko"]},
  
  {"id": 10, "kana": "さ", "en": ["sa"]},
  {"id": 11, "kana": "し", "en": ["shi"]},
  {"id": 12, "kana": "す", "en": ["su"]},
  {"id": 13, "kana": "せ", "en": ["se"]},
  {"id": 14, "kana": "そ", "en": ["so"]},
  
  {"id": 15, "kana": "た", "en": ["ta"]},
  {"id": 16, "kana": "ち", "en": ["chi"]},
  {"id": 17, "kana": "つ", "en": ["tsu"]},
  {"id": 18, "kana": "て", "en": ["te"]},
  {"id": 19, "kana": "と", "en": ["to"]},
  
  {"id": 20, "kana": "な", "en": ["na"]},
  {"id": 21, "kana": "に", "en": ["ni"]},
  {"id": 22, "kana": "ぬ", "en": ["nu"]},
  {"id": 23, "kana": "ね", "en": ["ne"]},
  {"id": 24, "kana": "の", "en": ["no"]},
  
  {"id": 25, "kana": "は", "en": ["ha"]},
  {"id": 26, "kana": "ひ", "en": ["hi"]},
  {"id": 27, "kana": "ふ", "en": ["fu"]},
  {"id": 28, "kana": "へ", "en": ["he"]},
  {"id": 29, "kana": "ほ", "en": ["ho"]},
  
  {"id": 30, "kana": "ま", "en": ["ma"]},
  {"id": 31, "kana": "み", "en": ["mi"]},
  {"id": 32, "kana": "む", "en": ["mu"]},
  {"id": 33, "kana": "め", "en": ["me"]},
  {"id": 34, "kana": "も", "en": ["mo"]},

  {"id": 35, "kana": "や", "en": ["ya"]},
  {"id": 36, "kana": "ゆ", "en": ["yu"]},
  {"id": 37, "kana": "よ", "en": ["yo"]},
  
  {"id": 38, "kana": "ら", "en": ["ra"]},
  {"id": 39, "kana": "り", "en": ["ri"]},
  {"id": 40, "kana": "る", "en": ["ru"]},
  {"id": 41, "kana": "れ", "en": ["re"]},
  {"id": 42, "kana": "ろ", "en": ["ro"]},
  
  {"id": 43, "kana": "わ", "en": ["wa"]},
  {"id": 44, "kana": "を", "en": ["wo"]},
  
  {"id": 45, "kana": "ん", "en": ["n"]}
]

export const DakuonHiragana = [
  {"id": 46, "kana": "が", "en": ["ga"]},
  {"id": 47, "kana": "ぎ", "en": ["gi"]},
  {"id": 48, "kana": "ぐ", "en": ["gu"]},
  {"id": 49, "kana": "げ", "en": ["ge"]},
  {"id": 50, "kana": "ご", "en": ["go"]},

  {"id": 51, "kana": "ざ", "en": ["za"]},
  {"id": 52, "kana": "じ", "en": ["ji"]},
  {"id": 53, "kana": "ず", "en": ["zu"]},
  {"id": 54, "kana": "ぜ", "en": ["ze"]},
  {"id": 55, "kana": "ぞ", "en": ["zo"]},

  {"id": 56, "kana": "だ", "en": ["da"]},
  {"id": 57, "kana": "ぢ", "en": ["ji"]},
  {"id": 58, "kana": "づ", "en": ["zu"]},
  {"id": 59, "kana": "で", "en": ["de"]},
  {"id": 60, "kana": "ど", "en": ["do"]},

  {"id": 61, "kana": "ば", "en": ["ba"]},
  {"id": 62, "kana": "び", "en": ["bi"]},
  {"id": 63, "kana": "ぶ", "en": ["bu"]},
  {"id": 64, "kana": "べ", "en": ["be"]},
  {"id": 65, "kana": "ぼ", "en": ["bo"]}
]

export const HandakuonHiragana = [
  {"id": 66, "kana": "ぱ", "en": ["pa"]},
  {"id": 67, "kana": "ぴ", "en": ["pi"]},
  {"id": 68, "kana": "ぷ", "en": ["pu"]},
  {"id": 69, "kana": "ぺ", "en": ["pe"]},
  {"id": 70, "kana": "ぽ", "en": ["po"]}
]

export const YouonHiragana = [
  {"id": 71, "kana": "きゃ", "en": ["kya"]},
  {"id": 72, "kana": "きゅ", "en": ["kyu"]},
  {"id": 73, "kana": "きょ", "en": ["kyo"]},

  {"id": 74, "kana": "しゃ", "en": ["sha"]},
  {"id": 75, "kana": "しゅ", "en": ["shu"]},
  {"id": 76, "kana": "しょ", "en": ["sho"]},

  {"id": 77, "kana": "ちゃ", "en": ["cha"]},
  {"id": 78, "kana": "ちゅ", "en": ["chu"]},
  {"id": 79, "kana": "ちょ", "en": ["cho"]},

  {"id": 80, "kana": "にゃ", "en": ["nya"]},
  {"id": 81, "kana": "にゅ", "en": ["nyu"]},
  {"id": 82, "kana": "にょ", "en": ["nyo"]},

  {"id": 83, "kana": "ひゃ", "en": ["hya"]},
  {"id": 84, "kana": "ひゅ", "en": ["hyu"]},
  {"id": 85, "kana": "ひょ", "en": ["hyo"]},

  {"id": 86, "kana": "みゃ", "en": ["mya"]},
  {"id": 87, "kana": "みゅ", "en": ["myu"]},
  {"id": 88, "kana": "みょ", "en": ["myo"]},

  {"id": 89, "kana": "りゃ", "en": ["rya"]},
  {"id": 90, "kana": "りゅ", "en": ["ryu"]},
  {"id": 91, "kana": "りょ", "en": ["ryo"]}
]

export const YouonDakuonHiragana = [
  {"id": 92, "kana": "ぎゃ", "en": ["gya"]},
  {"id": 93, "kana": "ぎゅ", "en": ["gyu"]},
  {"id": 94, "kana": "ぎょ", "en": ["gyo"]},

  {"id": 95, "kana": "じゃ", "en": ["jya"]},
  {"id": 96, "kana": "じゅ", "en": ["jyu"]},
  {"id": 97, "kana": "じょ", "en": ["jyo"]},

  {"id": 98, "kana": "ぢゃ", "en": ["jya"]},
  {"id": 99, "kana": "ぢゅ", "en": ["jyu"]},
  {"id": 100, "kana": "ぢょ", "en": ["jyo"]},

  {"id": 101, "kana": "びゃ", "en": ["bya"]},
  {"id": 102, "kana": "びょ", "en": ["byu"]},
  {"id": 103, "kana": "びょ", "en": ["byo"]},
]

export const YouonHandakuonHiragana = [
  {"id": 104, "kana": "ぴゃ", "en": ["pya"]},
  {"id": 105, "kana": "ぴゅ", "en": ["pyu"]},
  {"id": 106, "kana": "ぴょ", "en": ["pyo"]},
]

export const Katakana = [
  {"id": 107, "kana": "ア", "en": ["a"]},
  {"id": 108, "kana": "イ", "en": ["i"]},
  {"id": 109, "kana": "ウ", "en": ["u"]},
  {"id": 110, "kana": "エ", "en": ["e"]},
  {"id": 111, "kana": "オ", "en": ["o"]},
  
  {"id": 112, "kana": "カ", "en": ["ka"]},
  {"id": 113, "kana": "キ", "en": ["ki"]},
  {"id": 114, "kana": "ク", "en": ["ku"]},
  {"id": 115, "kana": "ケ", "en": ["ke"]},
  {"id": 116, "kana": "コ", "en": ["ko"]},
  
  {"id": 117, "kana": "サ", "en": ["sa"]},
  {"id": 118, "kana": "シ", "en": ["shi"]},
  {"id": 119, "kana": "ス", "en": ["su"]},
  {"id": 120, "kana": "セ", "en": ["se"]},
  {"id": 121, "kana": "ソ", "en": ["so"]},
  
  {"id": 122, "kana": "タ", "en": ["ta"]},
  {"id": 123, "kana": "チ", "en": ["chi"]},
  {"id": 124, "kana": "ツ", "en": ["tsu"]},
  {"id": 125, "kana": "テ", "en": ["te"]},
  {"id": 126, "kana": "ト", "en": ["to"]},
  
  {"id": 127, "kana": "ナ", "en": ["na"]},
  {"id": 128, "kana": "ニ", "en": ["ni"]},
  {"id": 129, "kana": "ヌ", "en": ["nu"]},
  {"id": 130, "kana": "ネ", "en": ["ne"]},
  {"id": 131, "kana": "ノ", "en": ["no"]},
  
  {"id": 132, "kana": "ハ", "en": ["ha"]},
  {"id": 133, "kana": "ヒ", "en": ["hi"]},
  {"id": 134, "kana": "フ", "en": ["fu"]},
  {"id": 135, "kana": "ヘ", "en": ["he"]},
  {"id": 136, "kana": "ホ", "en": ["ho"]},
  
  {"id": 137, "kana": "マ", "en": ["ma"]},
  {"id": 138, "kana": "ミ", "en": ["mi"]},
  {"id": 139, "kana": "ム", "en": ["mu"]},
  {"id": 140, "kana": "メ", "en": ["me"]},
  {"id": 141, "kana": "モ", "en": ["mo"]},

  {"id": 142, "kana": "ヤ", "en": ["ya"]},
  {"id": 143, "kana": "ユ", "en": ["yu"]},
  {"id": 144, "kana": "ヨ", "en": ["yo"]},
  
  {"id": 145, "kana": "ラ", "en": ["ra"]},
  {"id": 146, "kana": "リ", "en": ["ri"]},
  {"id": 147, "kana": "ル", "en": ["ru"]},
  {"id": 148, "kana": "レ", "en": ["re"]},
  {"id": 149, "kana": "ロ", "en": ["ro"]},
  
  {"id": 150, "kana": "ワ", "en": ["wa"]},
  {"id": 151, "kana": "ヲ", "en": ["wo"]},
  
  {"id": 152, "kana": "ン", "en": ["n"]}
]

export const DakuonKatakana = [
  {"id": 153, "kana": "ガ", "en": ["ga"]},
  {"id": 154, "kana": "ギ", "en": ["gi"]},
  {"id": 155, "kana": "グ", "en": ["gu"]},
  {"id": 156, "kana": "ゲ", "en": ["ge"]},
  {"id": 157, "kana": "ゴ", "en": ["go"]},

  {"id": 158, "kana": "ザ", "en": ["za"]},
  {"id": 159, "kana": "ジ", "en": ["ji"]},
  {"id": 160, "kana": "ズ", "en": ["zu"]},
  {"id": 161, "kana": "ゼ", "en": ["ze"]},
  {"id": 162, "kana": "ゾ", "en": ["zo"]},

  {"id": 163, "kana": "ダ", "en": ["da"]},
  {"id": 164, "kana": "ヂ", "en": ["ji"]},
  {"id": 165, "kana": "ヅ", "en": ["zu"]},
  {"id": 166, "kana": "デ", "en": ["de"]},
  {"id": 167, "kana": "ド", "en": ["do"]},

  {"id": 168, "kana": "バ", "en": ["ba"]},
  {"id": 169, "kana": "ビ", "en": ["bi"]},
  {"id": 170, "kana": "ブ", "en": ["bu"]},
  {"id": 171, "kana": "ベ", "en": ["be"]},
  {"id": 172, "kana": "ボ", "en": ["bo"]}
]

export const HandakuonKatakana = [
  {"id": 173, "kana": "パ", "en": ["pa"]},
  {"id": 174, "kana": "ピ", "en": ["pi"]},
  {"id": 175, "kana": "プ", "en": ["pu"]},
  {"id": 176, "kana": "ペ", "en": ["pe"]},
  {"id": 177, "kana": "ポ", "en": ["po"]}
]

export const YouonKatakana = [
  {"id": 178, "kana": "キャ", "en": ["kya"]},
  {"id": 179, "kana": "キュ", "en": ["kyu"]},
  {"id": 180, "kana": "キョ", "en": ["kyo"]},

  {"id": 181, "kana": "シャ", "en": ["sha"]},
  {"id": 182, "kana": "シュ", "en": ["shu"]},
  {"id": 183, "kana": "ショ", "en": ["sho"]},

  {"id": 184, "kana": "チャ", "en": ["cha"]},
  {"id": 185, "kana": "チュ", "en": ["chu"]},
  {"id": 186, "kana": "チョ", "en": ["cho"]},

  {"id": 187, "kana": "ニャ", "en": ["nya"]},
  {"id": 188, "kana": "ニュ", "en": ["nyu"]},
  {"id": 189, "kana": "ニョ", "en": ["nyo"]},

  {"id": 190, "kana": "ヒャ", "en": ["hya"]},
  {"id": 191, "kana": "ヒュ", "en": ["hyu"]},
  {"id": 192, "kana": "ヒョ", "en": ["hyo"]},

  {"id": 193, "kana": "ミャ", "en": ["mya"]},
  {"id": 194, "kana": "ミュ", "en": ["myu"]},
  {"id": 195, "kana": "ミョ", "en": ["myo"]},

  {"id": 196, "kana": "リャ", "en": ["rya"]},
  {"id": 197, "kana": "リュ", "en": ["ryu"]},
  {"id": 198, "kana": "リョ", "en": ["ryo"]}
]

export const YouonDakuonKatakana = [
  {"id": 199, "kana": "ギャ", "en": ["gya"]},
  {"id": 200, "kana": "ギュ", "en": ["gyu"]},
  {"id": 201, "kana": "ギョ", "en": ["gyo"]},

  {"id": 202, "kana": "ジャ", "en": ["jya"]},
  {"id": 203, "kana": "ジュ", "en": ["jyu"]},
  {"id": 204, "kana": "ジョ", "en": ["jyo"]},

  {"id": 205, "kana": "ヂャ", "en": ["jya"]},
  {"id": 206, "kana": "ヂュ", "en": ["jyu"]},
  {"id": 207, "kana": "ヂョ", "en": ["jyo"]},

  {"id": 208, "kana": "ビャ", "en": ["bya"]},
  {"id": 209, "kana": "ビュ", "en": ["byu"]},
  {"id": 210, "kana": "ビョ", "en": ["byo"]},
]

export const YouonHandakuonKatakana = [
  {"id": 211, "kana": "ピャ", "en": ["pya"]},
  {"id": 212, "kana": "ピュ", "en": ["pyu"]},
  {"id": 213, "kana": "ピョ", "en": ["pyo"]},
]

const Kana = [
  ...Hiragana,
  ...DakuonHiragana,
  ...HandakuonHiragana,

  ...YouonHiragana,
  ...YouonDakuonHiragana,
  ...YouonHandakuonHiragana,

  ...Katakana,
  ...DakuonKatakana,
  ...HandakuonKatakana,

  ...YouonKatakana,
  ...YouonDakuonKatakana,
  ...YouonHandakuonKatakana
]

export const getKanaById = (id) => {
  return Kana.find(kana => kana.id === id)
}

export const getPossibleEnKana = () => {
  let possibleEnKana = []
  Kana.forEach(kana => {
    console.log(kana.en)
    possibleEnKana = possibleEnKana.concat(kana.en)
  })
  console.log(possibleEnKana)
  return possibleEnKana
}

export default Kana