export const Food = [
  {"id": 0, "kanji": "お握り", "hiragana": "おにぎり", "romaji": "onigiri", "english":"rice ball"}
]

export const Animals = [
  {"id": 1, "kanji": "お握り", "hiragana": "おにぎり", "romaji": "onigiri", "english":"rice ball"}
]

export const Body = [
  {"id": 2, "kanji": "顔", "hiragana": "かお", "romaji": "kao", "english":"face"},
  {"id": 3, "kanji": "頭", "hiragana": "あたま", "romaji": "atama", "english":"head"},
  {"id": 4, "kanji": "髪", "hiragana": "かみ", "romaji": "kami", "english":"hair (on head)"},
  {"id": 5, "kanji": "眉毛", "hiragana": "まゆげ", "romaji": "mayuge", "english":"eyebrow"},
  {"id": 6, "kanji": "目", "hiragana": "め", "romaji": "me", "english":"eye"},
  {"id": 7, "kanji": "耳", "hiragana": "みみ", "romaji": "mimi", "english":"ear"},
  {"id": 8, "kanji": "鼻", "hiragana": "はな", "romaji": "hana", "english":"nose"},
  {"id": 9, "kanji": "口", "hiragana": "くち", "romaji": "kuchi", "english":"mouth"},
  {"id": 10, "kanji": "歯", "hiragana": "は", "romaji": "ha", "english":"tooth"},
  {"id": 11, "kanji": "体", "hiragana": "からだ", "romaji": "karada", "english":"body"},
  {"id": 12, "kanji": "首", "hiragana": "くび", "romaji": "hebi", "english":"neck"},
  {"id": 13, "kanji": "喉", "hiragana": "のど", "romaji": "nodo", "english":"throat"},
  {"id": 14, "kanji": "肩", "hiragana": "かた", "romaji": "kata", "english":"shoulder"},
  {"id": 15, "kanji": "腕", "hiragana": "うで", "romaji": "ude", "english":"arm"},
  {"id": 16, "kanji": "手", "hiragana": "て", "romaji": "te", "english":"hand"},
  {"id": 17, "kanji": "背中", "hiragana": "せなか", "romaji": "senaka", "english":"back"},
  {"id": 18, "kanji": "腰", "hiragana": "こし", "romaji": "koshi", "english":"lower back, waist, hips"},
  {"id": 19, "kanji": "お尻", "hiragana": "おしり", "romaji": "oshiri", "english":"bottom, buttocks"},
  {"id": 20, "kanji": "足", "hiragana": "あし", "romaji": "ashi", "english":"leg, foot"},
  {"id": 21, "kanji": "膝", "hiragana": "ひざ", "romaji": "hiza", "english":"knee, lap"},
  {"id": 22, "kanji": "胸", "hiragana": "むね", "romaji": "mune", "english":"chest, breast"},
  {"id": 23, "kanji": "お腹", "hiragana": "おなか", "romaji": "onaka", "english":"belly, stomach, lap"},
  {"id": 24, "kanji": "お臍", "hiragana": "おへそ", "romaji": "oheso", "english":"navel, belly button"},
  {"id": 25, "kanji": "膝", "hiragana": "ひざ", "romaji": "hiza", "english":"knee, lap"}
]

export const Places =[
  {"id": 26, "kanji": "寮", "hiragana": "りょう", "romaji": "ryou", "english":"hostel, dormitory"}
]

export const Vehicles = [
  {"id": 27, "kanji": "車", "hiragana": "くるま", "romaji": "kuruma", "english":"car"},
  {"id": 28, "kanji": "自動車", "hiragana": "じどうしゃ", "romaji": "jidousha", "english":"automobile"},
  {"id": 29, "kanji": "電気自動車", "hiragana": "でんきじどうしゃ", "romaji": "tenkijidousha", "english":"electric car"}
]

export const Events = [
  {"id": 30, "kanji": "宴会", "hiragana": "えんかい", "romaji": "enkai", "english":"party, banquet"},
  {"id": 31, "kanji": "飲み会", "hiragana": "のみかい", "romaji": "nomikai", "english":"drinking party"},
]

export const getNounById = ( nounId ) => {
  return Nouns.find(noun => noun.id === noun)
}

export const Nouns = [
  {"id": "food", "title": "Food and Drink", "data": Food},
  {"id": "body", "title": "Body", "data": Body},
  {"id": "places", "title": "Places", "data": Places},
  {"id": "vehicles", "title": "Vehicles", "data": Vehicles},
  {"id": "events", "title": "Events and Activities", "data": Events}
]

export const getNounTitles = () => {
  let titles= []

  Nouns.forEach((noun => {
    titles.push(noun.title)
  }))

  return titles;
}
