import PropTypes from 'prop-types'

const Header = ({ classes, text, onClick }) => {
    return (
        <button
            onClick={onClick}
            className={classes !== null ? classes : "button"}>
            {text}
        </button> 
    )
}

Header.propTypes= {
    classes: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func
}

export default Header