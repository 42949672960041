const Adjectives = [
    {"id": 0, "kanji": "綺麗", "hiragana": "きれい", "romaji": "kirei", "english":"Beautiful, Clean", "i": false},
    {"id": 1, "kanji": "赤", "hiragana": "あか", "romaji": "aka", "english": "Red", "i": true},
    {"id": 2, "kanji": "青", "hiragana": "あお", "romaji": "ao", "english": "Blue", "i": true},
    {"id": 3, "kanji": "緑", "hiragana": "みどりか", "romaji": "midori", "english": "Green", "i": false},
    {"id": 4, "kanji": "可愛", "hiragana": "かわい", "romaji": "kawai", "english": "Cute, Pretty", "i": true},
    {"id": 5, "kanji": "大切", "hiragana": "たいせつ", "romaji": "taisetsu", "english": "Important, Precious, Valuable", "i": false},
    {"id": 6, "kanji": "色々", "hiragana": "いろいろ", "romaji": "irorio", "english": "Various, Variety", "i": false},
    {"id": 7, "kanji": "派手", "hiragana": "はで", "romaji": "hade", "english": "Showy, Flashy, Gaudy, Loud", "i": false},
    {"id": 8, "kanji": "地味", "hiragana": "じみ", "romaji": "jime", "english": "Plain, Simple, Reserved", "i": false},
    {"id": 9, "kanji": "簡単", "hiragana": "かんたん", "romaji": "kantan", "english": "Simple, Easy, Breif", "i": false},
    {"id": 10, "kanji": "お洒落", "hiragana": "おしゃれ", "romaji": "oshare", "english": "Fashionable, Stylish", "i": false},
    {"id": 11, "kanji": "素敵", "hiragana": "すてき", "romaji": "suteki", "english": "Lovely, Wonderful, Great, Fantastic", "i": false},
    {"id": 12, "kanji": "楽し", "hiragana": "たのし", "romaji": "tanoshi", "english": "Enjoyable, Fun, Pleasant", "i": true},
    {"id": 13, "kanji": "多", "hiragana": "おお", "romaji": "Oo", "english": "Many, Numerous", "i": true},
    {"id": 14, "kanji": "少な", "hiragana": "すくな", "romaji": "shikuna", "english": "Few, Scarce", "i": true},
    {"id": 15, "kanji": "高", "hiragana": "たか", "romaji": "taka", "english": "High, Tall, Expensive", "i": true},
    {"id": 16, "kanji": "低", "hiragana": "ひく", "romaji": "hiku", "english": "Low (Rank, Quality, Degree), Low (Position), Short, Deep (Voice), Low (Volume)", "i": true},
    {"id": 17, "kanji": "広", "hiragana": "ひろ", "romaji": "hiro", "english": "Spacious, Vast, Wide", "i": true},
    {"id": 18, "kanji": "狭", "hiragana": "せま", "romaji": "sema", "english": "Narrow, Confined", "i": true},
    {"id": 19, "kanji": "重", "hiragana": "おも", "romaji": "omo", "english": "Heavy, Sluggish", "i": true},
    {"id": 20, "kanji": "軽", "hiragana": "かる", "romaji": "karu", "english": "Light (Weight)", "i": true},
    {"id": 21, "kanji": "太", "hiragana": "ふとい", "romaji": "futo", "english": "Thick", "i": true},
    {"id": 22, "kanji": "細", "hiragana": "ほそ", "romaji": "hoso", "english": "Thin, Slender", "i": true},
    {"id": 23, "kanji": "優し", "hiragana": "やさし", "romaji": "kawai", "english": "Kind, Gentle", "i": true},
    {"id": 24, "kanji": "厳し", "hiragana": "きびし", "romaji": "kibishi", "english": "Strict", "i": true},
    {"id": 25, "kanji": "硬", "hiragana": "かた", "romaji": "kata", "english": "Hard, Solid, Stiff, Firm", "i": true},
    {"id": 26, "kanji": "柔らか", "hiragana": "やわらかい", "romaji": "yawarakai", "english": "Soft, Gentle, Mild", "i": true},
    {"id": 27, "kanji": "長", "hiragana": "なが", "romaji": "naga", "english": "Long (Distance)", "i": true},
    {"id": 28, "kanji": "短", "hiragana": "みじか", "romaji": "mijika", "english": "Short", "i": true},
    {"id": 29, "kanji": "厚", "hiragana": "あつ", "romaji": "atsu", "english": "Thick (Objects)", "i": true},
    {"id": 30, "kanji": "薄", "hiragana": "うす", "romaji": "usu", "english": "Thin (Objects)", "i": true},
    {"id": 31, "kanji": "熱", "hiragana": "あつ", "romaji": "atsu", "english": "Hot", "i": true}
]


export const getAdjectiveById = ( adjectiveId ) => {
  return Adjectives.find(adjective => adjective.id === adjectiveId)
}
  
export default Adjectives