import { useState, useEffect } from 'react'

const MenuItem = ({id, title}) => {

  const [anchorTarget, setAnchorTarget] = useState(null)

  useEffect(() => {
    setAnchorTarget(document.getElementById(id));
  }, [id])

  function handleClick(event, selectedAnchor) {
    event.preventDefault();
    window.history.pushState("", "", window.location.pathname + "#" + selectedAnchor);
    anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start'});
  }

  return (
    <li key={id}>
      <a
         onClick={(event) => handleClick(event, id)}
         className="section-nav-item">
        {title}
      </a>
    </li>
  ); 
}

const SectionNav = ({sections}) => {
  return (
    <aside className="section-nav-aside">
      <div className="section-nav">
        <ul>
          {sections.map((section) => (
            <MenuItem id={section.id} title={section.title} />
          ))}
        </ul>
      </div>
    </aside>
  )
}

export default SectionNav