import Header from './../components/Header';

const VerbGroupsQuiz = () => {
  return (
    <div className="question-container">
      <Header title="Verb Groups"/>
    </div>
  );
}

export default VerbGroupsQuiz;
